import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { login } from '../../store/actions/authActions'
import bodyClassController from '../../store/utils/bodyClassController'
import setPageData from '../../store/actions/setPageData'
import {Link, useNavigate} from "react-router-dom";


import imgLogo from '../../assets/images/logo.png'

class LoginPageDtl extends React.Component {
    state = {
        user: '',
        password: '',
        remember: false,
        error: {},
        isLoading: false,
    }

    static getDerivedStateFromProps(nextProps, prevProps) {
        if (JSON.stringify(nextProps.auth.error) !== JSON.stringify(prevProps.error)) {
            return {
                error: nextProps.auth.error
            }
        }
        return null
    }

    changeHandler = event => {
        let stateName, stateValue;
        if (event.target.type == 'checkbox') {
            stateName = event.target.name;
            stateValue = event.target.checked;
        } else {
            stateName = event.target.name;
            stateValue = event.target.value;
        }


        this.setState({
            [stateName]: stateValue
        })
    }

    stopLoading = () => {
        this.setState({ isLoading: false })
    }

    submitHandler = event => {
        event.preventDefault()

        this.setState({ isLoading: true })

        let { user, password, remember } = this.state
        this.props.login({ user, password, remember, type: 'moderator' }, this.props.navigate, this.stopLoading)
    }

    componentWillMount() {
    }

    redirectHomeIfLoggedIn() {
        // Redirect home if already LoggedIn
        let { isAuthenticated, authChecking } = this.props.auth
        if (!authChecking && isAuthenticated) {
            this.props.navigate('/')
        }
    }

    componentDidMount() {
        this.props.setPageData({
            title: 'Login',
            pageID: 'login'
        });

    }
    render() {
        this.redirectHomeIfLoggedIn()

        this.props.bodyClassController(this.props.pageData);
        let { user, password, remember, error, isLoading } = this.state
        return (
            <>
                <Helmet> <title>{'Login here'}</title> </Helmet>

                <div className="container-fluid">
                    <div className="row page-auth">
                        <div className="col-md-4 page-content">
                            <div className="row">
                                {/* <div className="col-md-5 login-preview">
                                        <div className="preview-photo">
                                            <img src={imgLoginPage} alt="Login SMM Panel Maker" srcset=""/>
                                        </div>
                                    </div> */}
                                <div className="col-md-12 login-content">
                                    <div className="auth-brand">
                                        <img src={imgLogo} className="logo" alt="Make SMM Panel" />
                                    </div>

                                    {/* <h1 className="color-primary">Login</h1> */}
                                    <p className="text-center">Login to your dashboard</p>
                                    <form onSubmit={this.submitHandler} className="">
                                        <div className="form-group has-error mb-3">
                                            <input
                                                type="text"
                                                className={
                                                    (error.login ? "form-control is-invalid" : "form-control")
                                                }
                                                placeholder="Email or Username"
                                                name="user"
                                                id="user"
                                                autoFocus={true}
                                                tabIndex='1'
                                                onChange={this.changeHandler}
                                                value={user}
                                                required
                                            />
                                            {error.login && (
                                                <div className="invalid-feedback">
                                                    {error.login}
                                                </div>
                                            )}
                                        </div>
                                        <div className="form-group mb-3">
                                            <input
                                                type="password"
                                                className={
                                                    (error.login ? "form-control is-invalid" : "form-control")
                                                }
                                                placeholder="Password"
                                                name="password"
                                                id="password"
                                                tabIndex='2'
                                                onChange={this.changeHandler}
                                                value={password}
                                                required
                                            />
                                            {error.login && (
                                                <div className="invalid-feedback">
                                                    {error.login}
                                                </div>
                                            )}
                                        </div>
                                        <div className="form-group form-support mb-3">
                                            <div className="form-check remember-me">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    tabIndex='3'
                                                    name="remember"
                                                    value={remember}
                                                    onChange={this.changeHandler}
                                                    id="defaultCheck1" />

                                                <label className="form-check-label text-muted" htmlFor="defaultCheck1">
                                                    Remember Me
                                                </label>
                                            </div>

                                            <div className="form-check forgot-password text-muted">
                                                <Link to="/forgot-password" className="">Forgot password?</Link>
                                            </div>
                                        </div>

                                        <div className="text-right">
                                            <button
                                                type={isLoading ? 'button' : 'submit'}
                                                tabIndex='4'
                                                className={
                                                    (error.error ? "btn btn-primary mb-2 px-5 btn-block is-invalid" : "btn btn-primary mb-2 px-5 btn-block")
                                                }> {isLoading ? (<i className="fas fa-spinner fa-spin"></i>) : 'SIGN IN'} </button>

                                            {error.error && (
                                                <div className="invalid-feedback text-center">
                                                    {error.error}
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 preview-section">
                            <h1>B2B Data Vault</h1>
                            <p>Powering Your Progress with Precision Leads.</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    pageData: state.pageData,
})

const mapDispatchToProps = ({
    login,
    setPageData,
    bodyClassController
})
// export default connect( mapStateToProps, {login} )(Login)
// Wrapping the main class with this functional component
function LoginPage(props) {
    let navigate = useNavigate();
    return <LoginPageDtl {...props} navigate={navigate} />
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)

import axios from "axios";
import * as Types from "./types";
import configureAxios from "../utils/configureAxios";
import { manageError } from "../utils/axiosResponseError";

import { setDefaultUserData } from "./userData";
import {showError, showSuccess} from "../utils/configToast";

export const registrationAction =
	(user, history, stopLoading) => (dispatch) => {
		axios
			.post("user/registration", user)
			.then((res) => {
				stopLoading();

				let token = res.data.Session;
				let userData = res.data.User;

				localStorage.setItem("authToken", token);

				configureAxios(); // Set token to axios request header configure

				dispatch({
					type: Types.SET_USER,
					payload: {
						user: userData,
						error: {},
					},
				});
				history.push("/");
			})
			.catch((error) => {
				stopLoading();
				manageError(error);
			});
	};

export const updateUser = (user, history, stopLoading) => (dispatch) => {
	axios
		.post("user/update", user)
		.then((res) => {
			stopLoading();
			let { MsgSuccess } = res.data;
			let successMsg = MsgSuccess;
			showSuccess(successMsg);

			let userData = res.data.user;

			dispatch({
				type: Types.SET_USER,
				payload: {
					user: userData,
					error: {},
				},
			});
		})
		.catch((error) => {
			stopLoading();
			manageError(error);
		});
};

export const updatePassword =
	(user, history, stopLoading, resetPassFormData) => (dispatch) => {
		axios
			.post("user/change-password", user)
			.then((res) => {
				stopLoading();
				let { MsgSuccess } = res.data;
				let successMsg = MsgSuccess;
				showSuccess(successMsg);

				resetPassFormData();

				// let userData = res.data.user

				// dispatch({
				// 	type: Types.SET_USER,
				// 	payload: {
				// 		user: userData,
				// 		error: {}
				// 	}
				// })
			})
			.catch((error) => {
				stopLoading();
				let errorData;
				if (error.response) {
					errorData = error.response.data.join(", ");
				} else {
					errorData = "No response?";
				}
				showError(errorData);
			});
	};

export const login = (user, navigate, stopLoading) => (dispatch) => {
	axios
		.post("user/login", user)
		.then(async (res) => {
			stopLoading();
			let token = res.data.accessToken.token;
			let userData = res.data.userData;

			localStorage.setItem("authToken", token);

			await configureAxios(); // Set token to axios request header configure

			await dispatch({
				type: Types.SET_USER,
				payload: {
					user: userData,
					error: {},
				},
			});
			dispatch(setDefaultUserData()); // Load user basic data

			navigate("/");
		})
		.catch((error) => {
			stopLoading();
			manageError(error);
		});
};

export const loginOutAction = (navigate) => (dispatch) => {
	localStorage.removeItem("authToken");
	navigate("/");
	// Reload the page
	window.location.reload();
};

export const resetRequest =
	(user, history, stopLoading, resetForm) => (dispatch) => {
		axios
			.post("user/reset-request", user)
			.then(async (res) => {
				let { MsgSuccess } = res.data;
				showSuccess(MsgSuccess, "top-left");

				stopLoading();
				resetForm();
				// history.push('/reset-password')
			})
			.catch((error) => {
				stopLoading();
				manageError(error);
			});
	};

export const resetPassword = (user, history, stopLoading) => (dispatch) => {
	axios
		.post("user/reset-password", user)
		.then(async (res) => {
			let { MsgSuccess } = res.data;

			showSuccess(MsgSuccess, "top-left");
			stopLoading();
			history.push("/login");
		})
		.catch((error) => {
			stopLoading();
			manageError(error);
		});
};

export const logout = (history) => (dispatch) => {
	localStorage.removeItem("authToken");

	// Reset user data
	dispatch({
		type: Types.SET_USER,
		payload: {
			user: {},
		},
	});

	history.push("/login");
};

export const isLoggedIn = (forceLogin) => async (dispatch) => {
	let authToken = await localStorage.getItem("authToken");
	if (!authToken) {
		forceLogin();
		return;
	}

	// Update loading status
	dispatch({
		type: Types.USERS_AUTH_CHECKING,
		payload: {
			status: true,
		},
	});

	axios
		.get("/user/login-status")
		.then((res) => {
			// Update loading status
			dispatch({
				type: Types.USERS_AUTH_CHECKING,
				payload: {
					status: false,
				},
			});

			let userData = res.data;

			dispatch({
				type: Types.SET_USER,
				payload: {
					user: userData,
					error: {},
				},
			});

			// processForLoggedInUser()
			dispatch(setDefaultUserData()); // Load user basic data

			// history.push('/')
		})
		.catch((error) => {
			// Update loading status
			dispatch({
				type: Types.USERS_AUTH_CHECKING,
				payload: {
					status: false,
				},
			});

			if (!error.response) {
				return;
			}

			dispatch({
				type: Types.USERS_ERROR,
				payload: {
					error: error.response.data,
				},
			});

			forceLogin();
			manageError(error);
		});

	return;
};

export const setViewMode = (viewMode) => async (dispatch) => {
	dispatch({
		type: Types.USER_SET_VIEW_MODE,
		payload: {
			viewMode,
		},
	});
};

import React from "react";
import {Link, useNavigate} from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import Icofont from "react-icofont";

class SideMenuDtl extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: "",
			password: "",
			error: {},
		};
	}

	changeHandler = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	submitHandler = (event) => {
		event.preventDefault();

		this.props.logout(this.props.history);
	};

	render() {
		let { pageID } = this.props.pageData;
		let { user } = this.props.auth;
		return (
			<div className="col-md-1 p-0 side-menu">
			<nav className="collapse show sidebar-nav pt-4" id="left-nav">
				<div className={`nav-item ${["people_search"].includes(pageID) ? "active" : ""}`} >
					<Link to="/people" className="nav-link">
						<span className="icon-text">Search</span>
					</Link>
				</div>
				<div className={`nav-item ${["saved_list"].includes(pageID) ? "active" : ""}`} >
					<Link to="/people/list" className="nav-link">
						<span className="icon-text">List</span>
					</Link>
				</div>
				<div className={`nav-item ${["people_exports"].includes(pageID) ? "active" : ""}`} >
					<Link to="/people/exports" className="nav-link">
						<span className="icon-text">Exports</span>
					</Link>
				</div>
				<div className={`nav-item ${["user_enrichment"].includes(pageID) ? "active" : ""}`} >
					<Link to="/people/enrichment" className="nav-link">
						<span className="icon-text">Enrichment</span>
					</Link>
				</div>
			</nav>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	logout,
};

function SideMenu(props) {
	let navigate = useNavigate();
	return <SideMenuDtl {...props} navigate={navigate} />
}
export default
	connect(mapStateToProps, mapDispatchToProps)(SideMenu);

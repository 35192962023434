import React from "react";
import { connect } from "react-redux";
import {insertListAction} from "../../../store/actions/listActions";
import AsyncSelect from "react-select/async";
import { searchListAction } from '../../../store/actions/listActions';

class AddToListModal extends React.Component {
	state = {
		error: {},
		isLoading: false,
		list_id: "",
		name: "",
		csv_file: null,
		formRef: React.createRef(),
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		console.log(event)
		this.setState({
			[event.target.name]: event.target.value,
		});
	};
	handleFileChange = (event) => {
		const selectedFile = event.target.files[0];
		console.log(event.target.name)
		console.log(selectedFile)
		this.setState({
			[event.target.name]: selectedFile,
		});
	};

	resetFormData = () => {
		this.setState({
			name: "",
		});
		this.state.formRef.current.reset();
	};

	submitHandler = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });

		let { name, list_id } = this.state;
		let { selected_items } = this.props.query;

		const postData = { list_id, name, selectedItems:selected_items, };

		let insertReport = await this.props.insertListAction(postData);
		this.stopLoading();
		if (insertReport === true) {
			this.resetFormData();
		}
	};

	componentDidMount() {

	}

	render() {
		let { isLoading, error } = this.state;

		return (
			<form onSubmit={this.submitHandler} action=""
				  ref={this.state.formRef}>
				<div className="row">
					<div className="col-md-12">
						<div className="single-section">
							<div className="form-group has-error mb-3">
								<label htmlFor="title">Name/title</label>
								<AsyncSelect
									cacheOptions
									loadOptions={this.loadLists}
									defaultOptions
									placeholder="Saved list.."
									menuPortalTarget={document.body}
									onChange={(options) => {
										this.listChangeController(options, 'list_id')
									}}
								/>
							</div>


							<div className="form-actions text-right">
								<button
									type={isLoading ? "button" : "submit"}
									className="btn btn-primary is-invalid"
								>
									{" "}
									{isLoading ? (
										<i className="fas fa-spinner fa-spin"></i>
									) : (
										"Submit"
									)}{" "}
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
	}

	

	loadLists = async (keyword, callback) => {
		let type = "search_list";
		let filtered = await searchListAction({type, keyword});
		
		console.log("filtered");
		console.log(filtered);

		if(!filtered){
			filtered = [{ label: `Create new '${keyword}'`, value: keyword,  type: "new"}];
		}
		
		callback(filtered);
	};
	listChangeController = (options, key) => {
		if(options.type === "new"){
			this.setState({
				name: options.value,
				list_id: "",
			});
		}else{
			this.setState({
				name: "",
				list_id: options.value
			});
		}
	};
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	insertListAction: insertListAction,
};

// function UploadLeadsForm(props) {
// 	let navigate = useNavigate();
// 	return <UploadLeadsFormDtl {...props} navigate={navigate} />
// }
export default connect(mapStateToProps, mapDispatchToProps)(AddToListModal);

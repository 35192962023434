import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import bodyClassController from "../../store/utils/bodyClassController";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import SideMenu from "../../components/menu/sideMenu";
import PageBreadcrumb from "../../components/default/pageBreadcrumb";

import setPageData from "../../store/actions/setPageData";
import {useNavigate} from "react-router-dom";

class DashboardScreenDtl extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: {},
		};
	}

	setPageData() {
		this.props.setPageData({
			title: "All users",
			pageID: "dashboard_page",
			totalUsers: "...",
		});
	}

	componentDidMount() {
		this.setPageData();
	}

	componentWilMount() {}

	render() {
		this.props.bodyClassController(this.props.pageData);
		let { pageData, userData } = this.props;

		let userSummery;

		if (userData) {
			userSummery = userData.summery;
		}

		return (
			<>
				<Helmet>
					<title>{pageData.title}</title>{" "}
				</Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<SideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{`${pageData.title}`}</h1>
									</div>
								</div>

								<div className="page-content">
									{/*Dashboard*/}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	bodyClassController,
};


function DashboardScreen(props) {
	let navigate = useNavigate();
	return <DashboardScreenDtl {...props} navigate={navigate} />
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen);

import axios from "axios";
import * as Types from "./types";

import { manageError } from "../utils/axiosResponseError";
import {showSuccess} from "../utils/configToast";
import queryString from 'query-string';


export const retrieveExports = (data) => (dispatch) => {
	const queryParams = queryString.stringify(data);

	axios
		.get(`/lead/export?${queryParams}`)
		.then((res) => {
			let responseData = res.data;

			dispatch({
				type: Types.LEAD_EXPORT_LIST,
				payload: {
					response: responseData,
					error: {},
				},
			});
		})
		.catch((error) => {
			manageError(error);
		});
};

export const insertExport = (data) => async (dispatch) => {
	let returnType;
	await axios
		.post("/lead/export", data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => {
			let promoInfo = res.data.data;

			showSuccess("Successfully created");

			dispatch({
				type: Types.LEAD_EXPORT_INSERT,
				payload: {
					promoInfo,
					error: {},
				},
			});
			returnType = true;
		})
		.catch((error) => {
			manageError(error);
		});
	return returnType;
};
import React from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../store/actions/authActions'

class PageBreadcrumbDtl extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: '',
			password: '',
			error: {},
		}
	}

	changeHandler = event => {
		this.setState({
			[event.target.name]: event.target.value
		})
	}

	submitHandler = event => {
		event.preventDefault()

		this.props.logout(this.props.history)
	}

	render() {
		const {
			location: { pathname }
		} = this.props;

		console.log("pathname")
		console.log(pathname)
		const pathnames = pathname.split("/").filter(x => x);

		if(1==1){
			return (
				<div className="mb-4"></div>
			)
		}else {
			return (
				<>
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							{pathnames.length > 0 && (
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
							)}

							{pathnames.map((name, index) => {
								const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
								const isLast = index === pathnames.length - 1;
								return isLast ? (
									// <Typography key={name}>{name}</Typography>
									// <li className="breadcrumb-item"><Link to="/">{name}</Link></li>
									<li className="breadcrumb-item active" aria-current="page">{name}</li>
								) : (
									// <Link key={name} onClick={() => history.push(routeTo)}> {name} </Link>
									<li className="breadcrumb-item"><Link to={routeTo}>{name}</Link></li>
								);
							})}

							{/* <li className="breadcrumb-item"><Link to="/">Home</Link></li> */}
							{/* <li className="breadcrumb-item"><Link to="/">Sub</Link></li> */}
							{/* <li className="breadcrumb-item active" aria-current="page">Current Page Title</li> */}
						</ol>
					</nav>
				</>
			)
		}
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
})
const mapDispatchToProps = ({
	logout
})


function PageBreadcrumb(props) {
	let navigate = useNavigate();
	let location = useLocation();
	return <PageBreadcrumbDtl {...props} navigate={navigate} location={location}/>
}
export default connect(mapStateToProps, mapDispatchToProps)(PageBreadcrumb)
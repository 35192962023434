// import { toast } from 'react-toastify';

import {toast} from "react-toastify";

export const showSuccess = (message, position) => {
	position = position ? position : "bottom-right"

	toast.success(message, {
		position: position,
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
}
export const showError = (message, position) => {
	position = position ? position : "bottom-right"

	toast.error(message, {
		position: position,
		autoClose: 8000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
}
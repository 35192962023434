import React from "react";
import {Link, useNavigate} from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import Icofont from "react-icofont";

class SideMenuAdminDtl extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: "",
			password: "",
			error: {},
		};
	}

	changeHandler = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	submitHandler = (event) => {
		event.preventDefault();

		this.props.logout(this.props.history);
	};

	render() {
		let { pageID } = this.props.pageData;
		let { user } = this.props.auth;
		return (
			<div className="col-md-2 side-menu">
				<nav className="collapse show sidebar-nav pt-4" id="left-nav">
					<div
						className={
							pageID === "admin_leads"
								? "nav-item active"
								: "nav-item"
						}
					>
						<Link to="/admin/leads" className="nav-link">
							<span className="icon-text">Leads</span>
						</Link>
					</div>
				</nav>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	logout,
};

function SideMenuAdmin(props) {
	let navigate = useNavigate();
	return <SideMenuAdminDtl {...props} navigate={navigate} />
}
export default
	connect(mapStateToProps, mapDispatchToProps)(SideMenuAdmin);

import React from "react";
import { connect } from "react-redux";
import {importEnrichment} from "../../../store/actions/enrichmentActions";

class ImportEnrichmentForm extends React.Component {
	state = {
		error: {},
		isLoading: false,
		name: "",
		csv_file: null,
		formRef: React.createRef(),
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		console.log(event)
		this.setState({
			[event.target.name]: event.target.value,
		});
	};
	handleFileChange = (event) => {
		const selectedFile = event.target.files[0];
		if(selectedFile){
			this.setState({
				[event.target.name]: selectedFile,
			});
			if(selectedFile.name){
				this.setState({
					name: selectedFile.name,
				});
			}
		}else{
			this.setState({
				[event.target.name]: null,
				name: '',
			});
		}
	};

	resetFormData = () => {
		this.setState({
			name: "",
		});
		this.state.formRef.current.reset();
	};

	submitHandler = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });

		let { name, csv_file } = this.state;
		const formData = new FormData();
		formData.append('name', name);
		formData.append('csv_file', csv_file);

		let insertReport = await this.props.importEnrichment(formData);
		this.stopLoading();
		if (insertReport === true) {
			this.resetFormData();
		}
	};

	// const handleFileChange = (event) => {
	// 	const file = event.target.files[0]; // Get the first file from the FileList object
	// 	if (file) {
	// 		setFileName(file.name); // Update state with the file name
	// 	}
	// };

	componentDidMount() {

	}

	render() {
		let { isLoading, error } = this.state;

		return (
			<form onSubmit={this.submitHandler} action=""
				  ref={this.state.formRef}>
				<div className="row">
					<div className="col-md-12">
						<div className="single-section">
							<div className="form-group has-error mb-3">
								<label htmlFor="rangeFrom">CSV File</label>
								<input
									type="file"
									accept=".csv, text/csv"
									className={
										error.csv_file
											? "form-control is-invalid"
											: "form-control"
									}
									name="csv_file"
									id="csv_file"
									onChange={this.handleFileChange}
									required={true}
								/>
								{error.csv_file && (
									<div className="invalid-feedback">
										{error.csv_file}
									</div>
								)}
							</div>

							<div className="form-group has-error mb-3">
								<label htmlFor="title">Name/title</label>
								<input
									type="text"
									className={
										error.name
											? "form-control is-invalid"
											: "form-control"
									}
									name="name"
									id="name"
									value={this.state.name}
									onChange={this.changeHandler}
									required={true}
								/>
								{error.name && (
									<div className="invalid-feedback">
										{error.name}
									</div>
								)}
							</div>

							<div className="form-actions text-right">
								<button
									type={isLoading ? "button" : "submit"}
									className="btn btn-primary is-invalid"
								>
									{" "}
									{isLoading ? (
										<i className="fas fa-spinner fa-spin"></i>
									) : (
										"Submit"
									)}{" "}
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	importEnrichment: importEnrichment,
};

// function UploadLeadsForm(props) {
// 	let navigate = useNavigate();
// 	return <UploadLeadsFormDtl {...props} navigate={navigate} />
// }
export default connect(mapStateToProps, mapDispatchToProps)(ImportEnrichmentForm);

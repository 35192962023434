import React from "react";
import {useNavigate} from "react-router-dom";
import { connect } from "react-redux";
import {isLoggedIn, loginOutAction} from "../../store/actions/authActions";
import configureAxios from "../../store/utils/configureAxios";

class LogoutPageDtl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    authCheck() {
        this.props.loginOutAction(this.props.navigate)
    }
    componentDidMount() {}

    render() {
        this.authCheck();
        return (
            <div className="page-auth-checking">
                <div className="checking-content">
                    <div className="footer">
                        <i className="fas fa-spinner fa-spin"></i> Processing...
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});
const mapDispatchToProps = {
    loginOutAction: loginOutAction,
};

function LogoutPage(props) {
    let navigate = useNavigate();
    return <LogoutPageDtl {...props} navigate={navigate} />
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);

import React from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import configureAxios from "./store/utils/configureAxios";
import LoginPage from "./modules/auth/user_login";

import { isLoggedIn, setViewMode } from "./store/actions/authActions";
import {connect} from "react-redux";
import TestPage from "./modules/auth/test_page";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthCheckingPage from "./modules/auth/auth_hecking_page";
import DashboardScreen from "./modules/dashboard/dashboard_screen";
import PeopleScreen from "./modules/people/people_screen";
import AdminScreen from "./modules/admin/admin_screen";
import LeadsPage from "./modules/admin/leads_screen";
import EnrichmentPage from "./modules/enrichment/enrichment_screen";
import LogoutPage from "./modules/auth/user_logout";
import ExportsPage from "./modules/people/exports_screen";
import SavedListPage from './modules/people/saved_list_screen';

class MyRoutes extends React.Component {
	state = {
		user: "",
		viewMode: null,
		password: "",
		error: {},
	};
	async componentWillMount() {
		configureAxios(); // Run Basic Axios Configure
	}

	componentDidMount() {}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			JSON.stringify(prevProps.auth) !== JSON.stringify(this.props.auth)
		) {
			let viewAsAgent = localStorage.getItem("viewAsAgent");

			let { user } = this.props.auth;

			let viewMode = "business";

			this.props.setViewMode(viewMode);
		}
	}

	render() {
		let { isAuthenticated } = this.props.auth;
		let { viewMode } = this.props.auth;
		let isAdmin = true;

		// document.documentElement.style.setProperty(
		// 	"--primaryColor",
		// 	`#39E46E`
		// );

		return (
			<BrowserRouter>
				{(() => {
					if(isAuthenticated){
						return (
							<Routes>
								{/*<Route path="/" element={<DashboardScreen />} />*/}
								<Route path="/" element={<PeopleScreen />} />
								<Route path="/people" element={<PeopleScreen />} />
								<Route path="/people/list" element={<SavedListPage />} />
								<Route path="/people/exports" element={<ExportsPage />} />
								<Route path="/people/enrichment" element={<EnrichmentPage />} />
								<Route path="/logout" element={<LogoutPage />} />
								<Route
									path="/admin"
									element={isAdmin ? <LeadsPage /> : <AuthCheckingPage />}
								/>
								<Route
									path="/admin/leads"
									element={isAdmin ? <LeadsPage /> : <AuthCheckingPage />}
								/>
							</Routes>
						)
					}else{
						return (
							<Routes>
								<Route path="/" element={<AuthCheckingPage />} />
								<Route path="/login" element={<LoginPage />} />
								<Route path="/*" element={<AuthCheckingPage />} />
							</Routes>
						)
					}
				})()}

				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					pauseOnFocusLoss={false}
					draggable
					pauseOnHover
				/>
				<ToastContainer />
			</BrowserRouter>
		);
	}
}

// export default App;

const mapStateToProps = (state) => ({
	auth: state.auth,
});
const mapDispatchToProps = {
	isLoggedin: isLoggedIn,
	setViewMode,
};
export default connect(mapStateToProps, mapDispatchToProps)(MyRoutes);

import React from "react";
import { connect } from "react-redux";
import {importLeads} from "../../../store/actions/leadActions";

class ImportLeadsForm extends React.Component {
	state = {
		error: {},
		isLoading: false,
		name: "",
		csv_file: null,
		formRef: React.createRef(),
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		console.log(event)
		this.setState({
			[event.target.name]: event.target.value,
		});
	};
	handleFileChange = (event) => {
		const selectedFile = event.target.files[0];
		console.log(event.target.name)
		console.log(selectedFile)
		this.setState({
			[event.target.name]: selectedFile,
		});
	};

	resetFormData = () => {
		this.setState({
			name: "",
		});
		this.state.formRef.current.reset();
	};

	submitHandler = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });

		let { name, csv_file } = this.state;
		const formData = new FormData();
		formData.append('name', name);
		formData.append('csv_file', csv_file);

		let insertReport = await this.props.importLeads(formData);
		this.stopLoading();
		if (insertReport === true) {
			this.resetFormData();
		}
	};

	componentDidMount() {

	}

	render() {
		let { isLoading, error } = this.state;

		return (
			<form onSubmit={this.submitHandler} action=""
				  ref={this.state.formRef}>
				<div className="row">
					<div className="col-md-12">
						<div className="single-section">
							<div className="form-group has-error mb-3">
								<label htmlFor="title">Import title</label>
								<input
									type="text"
									className={
										error.name
											? "form-control is-invalid"
											: "form-control"
									}
									name="name"
									id="name"
									value={this.state.name}
									onChange={this.changeHandler}
									required={true}
								/>
								{error.name && (
									<div className="invalid-feedback">
										{error.name}
									</div>
								)}
							</div>

							<div className="form-group has-error mb-3">
								<label htmlFor="rangeFrom">CSV File</label>
								<input
									type="file"
									accept=".csv, text/csv"
									className={
										error.csv_file
											? "form-control is-invalid"
											: "form-control"
									}
									name="csv_file"
									id="csv_file"
									onChange={this.handleFileChange}
									required={true}
								/>
								{error.csv_file && (
									<div className="invalid-feedback">
										{error.csv_file}
									</div>
								)}
							</div>


							<div className="form-actions text-right">
								<button
									type={isLoading ? "button" : "submit"}
									className="btn btn-primary is-invalid"
								>
									{" "}
									{isLoading ? (
										<i className="fas fa-spinner fa-spin"></i>
									) : (
										"Submit"
									)}{" "}
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	importLeads: importLeads,
};

// function UploadLeadsForm(props) {
// 	let navigate = useNavigate();
// 	return <UploadLeadsFormDtl {...props} navigate={navigate} />
// }
export default connect(mapStateToProps, mapDispatchToProps)(ImportLeadsForm);

import * as Types from "../actions/types";

const init = {
	data: {},
	pagination: {},
	error: {},
	selected: {},
	success: "",
};

const listReducer = (state = init, action) => {
	switch (action.type) {
		case Types.PEOPLE_LIST: {
			return {
				...state,
				data: action.payload.response.data,
				pagination: action.payload.response.pagination,
				selected: {},
				error: {},
			};
		}

		case Types.PEOPLE_LIST_INSERT: {
			if (Object.keys(state.data).length) {
				if (action.payload.data) {
					state.data.unshift(action.payload.data);
				}
			} else {
				state.data = action.payload.data;
			}

			return {
				...state,
			};
		}

		default:
			return state;
	}
};

export default listReducer;

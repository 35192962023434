import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { login } from '../../store/actions/authActions'
import bodyClassController from '../../store/utils/bodyClassController'
import setPageData from '../../store/actions/setPageData'
import {useNavigate} from "react-router-dom";

class TestPageDtl extends React.Component {
    state = {
        user: '',
        password: '',
        remember: false,
        error: {},
        isLoading: false,
    }

    static getDerivedStateFromProps(nextProps, prevProps) {
        if (JSON.stringify(nextProps.auth.error) !== JSON.stringify(prevProps.error)) {
            return {
                error: nextProps.auth.error
            }
        }
        return null
    }

    changeHandler = event => {
        let stateName, stateValue;
        if (event.target.type == 'checkbox') {
            stateName = event.target.name;
            stateValue = event.target.checked;
        } else {
            stateName = event.target.name;
            stateValue = event.target.value;
        }


        this.setState({
            [stateName]: stateValue
        })
    }

    stopLoading = () => {
        this.setState({ isLoading: false })
    }

    submitHandler = event => {
        event.preventDefault()

        this.setState({ isLoading: true })

        let { user, password, remember } = this.state
        this.props.login({ user, password, remember, type: 'moderator' }, this.props.navigate, this.stopLoading)
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.props.setPageData({
            title: 'Login',
            pageID: 'login'
        });

    }
    render() {
        this.props.bodyClassController(this.props.pageData);
        let { user, password, remember, error, isLoading } = this.state
        return (
            <>
                <Helmet> <title>{'Login here'}</title> </Helmet>

                <div className="container-fluid">
                    Home page
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    pageData: state.pageData,
})

const mapDispatchToProps = ({
    login,
    setPageData,
    bodyClassController
})
// export default connect( mapStateToProps, {login} )(Login)
// Wrapping the main class with this functional component
function TestPage(props) {
    let navigate = useNavigate();
    return <TestPageDtl {...props} navigate={navigate} />
}

export default connect(mapStateToProps, mapDispatchToProps)(TestPage)

import React from "react";
import {Link, useNavigate} from "react-router-dom";
import { connect } from "react-redux";
import {loginOutAction, logout} from "../../store/actions/authActions";

import imgLogo from "../../assets/images/logo.png";

class PrimaryMenuDtl extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: "",
			password: "",
			error: {},
		};
	}

	handleLogout = (event) => {
		event.preventDefault();
		this.props.loginOutAction(this.props.navigate)
	};

	changeHandler = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	submitHandler = (event) => {
		event.preventDefault();

		this.props.logout(this.props.history);
	};

	render() {
		let { pageID } = this.props.pageData;
		let { auth } = this.props;
		let { user, viewMode } = auth;
		let username;

		try {
			username = auth.user.firstName;
		} catch (error) {
			console.log("Username", error);
		}
		return (
			<nav className="navbar navbar-expand-lg sticky-top navbar-light bg-light">
				<div className="container-fluid">
					<Link to="/" className="navbar-brand">
						<img src={imgLogo} className="logo" width="25" height="25" alt=""/>
					</Link>
					<button
						className="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon" />
					</button>
					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav mr-auto">
							<li className={`nav-item ${["people_search", "user_enrichment", "people_exports", "saved_list"].includes(pageID) ? "active" : ""}`} >
								<Link to="/" className="nav-link">People</Link>
							</li>
							<li
								className={
									pageID === "admin_page"
										? "nav-item active"
										: "nav-item"
								}>
								<Link to="/admin" className="nav-link">Admin</Link>
							</li>
							<li className="nav-item">
								<a href="#"
								   onClick={this.handleLogout}
								   className="nav-link  text-danger">Logout</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	logout,
	loginOutAction: loginOutAction,
};


function PrimaryMenu(props) {
	let navigate = useNavigate();
	return <PrimaryMenuDtl {...props} navigate={navigate} />
}
export default connect(mapStateToProps, mapDispatchToProps)(PrimaryMenu);
